//https://www.debugbear.com/blog/core-web-vitals-js provides guidance to collect cls and inp with vanilla js

import {onLCP, onFID, onCLS, onFCP, onINP, onTTFB } from 'web-vitals/attribution';
import * as ut from "./ut";
import {_gtag_set} from "./ga";
import { push } from "./datalayer";

let pushed = [];

function handleCWV(measure){
    let name = measure.name.toLowerCase(),
        value = Math.round(measure.value); //cls fix decimal

    ut.setPage(name, value);
    _gtag_set({[name]: value});

    if (pushed.indexOf(name) === -1){
        pushed.push(name);

        let obj = {
            event: 'web_vital',
            ec: name,
            ev: value,
            el: measure.rating,
            af: measure.id
        };

        if (name === 'cls'){
            obj.at = measure.attribution.largestShiftTarget;
        }
        else if (name === 'inp'){
            obj.at = measure.attribution.interactionTarget;
        }
        else if (name === 'lcp'){
            obj.at = measure.attribution.element;
        }

        push(obj);
    }
}

let config = { reportAllChanges: true };
onTTFB(handleCWV, config);
onFCP(handleCWV, config);
onCLS(handleCWV, config);
onFID(handleCWV, config);
onLCP(handleCWV, config);
onINP(handleCWV, config); //https://web.dev/inp/ https://web.dev/blog/inp-cwv-march-12?hl=en