//https://docs.google.com/spreadsheets/d/1LZB05NoBIfoyONKtLzEX0HOhX_bgvnGu6PEkP21A6Wk/edit?gid=0#gid=0

import {wuphf} from "./storage";
import {_gtag_set, GAEvent} from "./ga";
import {gup, isInt, isUndefined, getUrlParams, ensureItemIsArray, $name} from "./helpers";
import {addReadyEvent, clarityReady} from './events';
import * as ut from "./ut";
import {push} from "./datalayer";
import {config} from "./config";
import {injectCSS} from "./load_assets";
import {$qs, forAll} from "./dom";
import {getDeviceType} from "./client_detection";

export let experiments_on_page = {};
let variant_labels = ['control', 'variant1', 'variant2', 'variant3', 'variant4'],
    experiment_options = ['name', 'start', 'end', 'device', 'delay'];

export function testBucket(opts) {
    opts = opts || {};

    if (typeof opts === 'string') {
        opts = {
            test: opts
        };
    }

    if (isUndefined(opts.global_var)){
        opts.global_var = true;
    }

    opts.disabled = ensureItemIsArray(opts.disabled);

    if (typeof opts.variants === 'number'){
        let count = opts.variants;
        opts.variants = ['control'];
        for (let i=1; i<count; i++){
            if (!opts.disabled.includes('variant'+i) && !opts.disabled.includes(i)){
                opts.variants.push('variant'+i);
            }
        }
    }

    opts.expire = opts.expire || 35;

    let buckets = Array.isArray(opts.variants) ? opts.variants : ['control', 'variant1'],
        bucket = buckets[Math.floor(Math.random() * buckets.length)];
        // variant_index = 0;

    // if (bucket !== 'control'){
    //     variant_index = bucket.replace('variant', '');
    // }

    let test_name = opts.test || opts.name;
    if (test_name) {
        let stored_bucket = wuphf(test_name),
            variable = window[test_name],
            url = gup(test_name);

        if (buckets.indexOf(stored_bucket) > -1) {
            bucket = stored_bucket;
        }

        if (buckets.indexOf(url) > -1) {
            bucket = url;
        }

        if (typeof variable === 'string' && buckets.indexOf(variable) > -1) {
            bucket = variable;
        }

        if (opts.id){
            let params = getUrlParams();
            for (let param in params){
                if (param.indexOf('experiment_') === 0){
                    let id = param.replace('experiment_', '');

                    if (id === opts.id && buckets.indexOf(params[param]) > -1){
                        bucket = params[param];
                    }
                }
            }
        }

        //allow for buckets to be passed as ints
        if (!!(bucket+'') && !isNaN(parseInt(bucket))){
            if (bucket+'' === '0'){
                bucket = 'control';
            }
            else {
                bucket = 'variant'+bucket;
            }
        }

        if (typeof opts.expire === 'number') {
            wuphf(test_name, bucket, opts.expire);
        }

        if (opts.global_var === true) {
            window[test_name] = bucket;
        }

        // if (opts.id){
        //     window.CE_SNAPSHOT_NAME = opts.id+'.'+variant_index;
        // }

        clarityReady(function(){
            //https://learn.microsoft.com/en-us/clarity/clarity-api#add-custom-tags
            window.clarity('set', test_name, bucket);
        });
    }

    return bucket;
}

let experiments = {
    prod: [
        // {
        //     id: 'NQA7Q9O7Tj-UiLjFkQy9bw',
        //     index: 44,
        //     name: 'Nav donation form in button',
        //     variants: 4
        // }
    ]
};

export function createTests(){
    let experiment_class = '.experiment',
        control_class = '.control',
        variant1_class = '.variant1',
        variant2_class = '.variant2',
        variant3_class = '.variant3',
        variant4_class = '.variant4';

    let css = injectCSS(`
        ${experiment_class}[data-control], ${experiment_class} [data-control],
        ${experiment_class}${control_class}, ${experiment_class} ${control_class}, 
        ${experiment_class}${variant1_class}, ${experiment_class} ${variant1_class}, 
        ${experiment_class}${variant2_class}, ${experiment_class} ${variant2_class}, 
        ${experiment_class}${variant3_class}, ${experiment_class} ${variant3_class}, 
        ${experiment_class}${variant4_class}, ${experiment_class} ${variant4_class} {display:none}
    `);

    addReadyEvent(function(){
        if (window.$experiments && typeof window.$experiments === 'object' && Object.keys(window.$experiments).length){
            for (let experiment in window.$experiments){
                let proceed = true;

                for (let prop in window.$experiments[experiment]){
                    if (variant_labels.includes(prop)){
                        if (!$qs(window.$experiments[experiment][prop])){
                            proceed = false;
                        }
                    }
                }

                if (proceed){
                    for (let prop in window.$experiments[experiment]){
                        if (variant_labels.includes(prop)){
                            let temp = $qs(window.$experiments[experiment][prop]);
                            if (temp){
                                temp.classList.add('experiment', prop);
                                temp.dataset.id = experiment;

                                experiment_options.forEach(function(opt){
                                    let option = window.$experiments[experiment][opt];
                                    if (option){
                                        temp.dataset[opt] = option.replace(/ /g, '_').replace(/"/g, '');
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        forAll(experiment_class, function(e){
            let experiment_id = e.dataset.experimentId || e.dataset.experimentid || e.dataset.experiment_id || e.dataset.id || e.dataset.exId || e.dataset.ex_id;

            if (!experiment_id){
                return;
            }

            if (!experiments_on_page[experiment_id]){
                experiments_on_page[experiment_id] = {};
            }

            experiment_options.forEach(function(option){
                let value = e.dataset[option];
                if (value && value.trim() && !experiments_on_page[experiment_id][option]){
                    experiments_on_page[experiment_id][option] = value.trim();
                }
            });

            variant_labels.forEach(function(bucket){
                let target_parent_element = e.classList.contains(bucket),
                    target_children_elements = false;

                if (!target_parent_element){
                    target_children_elements = $qs('.'+bucket, e);
                }

                if (target_parent_element || target_children_elements){
                    if (!experiments_on_page[experiment_id][bucket]){
                        experiments_on_page[experiment_id][bucket] = [];
                    }

                    experiments_on_page[experiment_id][bucket].push(target_parent_element ? e : target_children_elements);
                }
            });

            //TODO handle <div class="experiment" data-experimentId="swc" data-control="zxv" data-variant1="vxz"></div>
        });

        for (let experiment_id in experiments_on_page){
            let test_variants = [],
                do_experiment = true;

            for (let prop in experiments_on_page[experiment_id]){
                let option = experiments_on_page[experiment_id][prop];

                if (variant_labels.includes(prop)){
                    test_variants.push(prop);
                }
                else if (prop === 'device' && option.indexOf(getDeviceType()) === -1){
                    do_experiment = false;
                }
                else if (prop === 'start' && new Date() < new Date(option)){
                    do_experiment = false;
                }
                else if (prop === 'end' && new Date() >= new Date(option)){
                    do_experiment = false;
                }
            }

            if (test_variants.length < 2){
                do_experiment = false;
            }

            experiments_on_page[experiment_id].enabled = do_experiment;

            let bucket = !do_experiment ? 'control' : testBucket({
                test: experiment_id,
                variants: test_variants
            });

            experiments_on_page[experiment_id].active_variant = bucket;

            for (let prop in experiments_on_page[experiment_id]){
                if (variant_labels.includes(prop)){
                    if (prop !== bucket){
                        experiments_on_page[experiment_id][prop].forEach(function(e){
                            e.remove();
                        });
                    }
                }
            }

            if (do_experiment){
                experiment({
                    name: experiments_on_page[experiment_id].name || null,
                    experiment_id: experiment_id,
                    group: bucket
                });
            }
        }

        css.remove();
    });
}

export function experiment(opts){
    let experiment_impression = 'experiment_impression',
        experiment_id = opts.experiment_id,
        variant_id = experiment_id+'.',
        group = opts.group;

    opts.name = opts.name || 'experiment '+experiment_id;

    if (isInt(group)){
        variant_id += group;
    }
    else if (group === 'control'){
        variant_id += '0';
    }
    else if (group === 'test'){
        variant_id += '1';
    }
    else if (group.indexOf('variant') === 0){
        variant_id += group.replace('variant', '');
    }
    else {
        return;
    }

    let data = {
        experiment_id: experiment_id,
        variant_id: variant_id,
    };

    addReadyEvent(function(){
        let prefix = 'experiment-',
            body_classes_to_add = [prefix+experiment_id, prefix+variant_id];

        if (opts.name && opts.name.indexOf('experiment ') !== 0){
            opts.name = opts.name.toLowerCase().replace(/ /g, '_');
            body_classes_to_add = body_classes_to_add.concat(prefix+opts.name, prefix+opts.name+'.'+variant_id.split('.')[1]);
        }

        if (opts.class){
            body_classes_to_add.push(prefix+opts.class);
        }

        requestAnimationFrame(function(){
            $name('body').classList.add(...body_classes_to_add);
        });
    });

    ut.setUser({variant_id: variant_id});
    push({event: opts.name, ...data});
    _gtag_set({opt_variant_id: ut.getUser('variant_id') || variant_id});

    GAEvent(experiment_impression, {...data});

    for (let env in experiments){
        for (let i=0; i<experiments[env].length; i++){
            if (experiments[env][i].id === experiment_id){
                if (experiments[env][i].crazyegg){
                    if (experiments[env][i].crazyegg === true){
                        window.CE_SNAPSHOT_NAME = variant_id;
                    }
                    else if (experiments[env][i].crazyegg[group]){
                        window.CE_SNAPSHOT_NAME = experiments[env][i].crazyegg[group];

                        if (window.CE2){
                            delete window.CE2; //could cause errors
                        }

                        addLoadEvent(function(){
                            loadScript(config.CE);
                        });
                    }
                }
            }
        }
    }
}