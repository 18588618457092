import {config} from "./config";
import {trim, isString} from "./helpers";

export function isLinkLink(link) {
    link = trim(link);
    return (isString(link) && link.indexOf('#') !== 0 && link.indexOf('javascript') !== 0 && !(link.indexOf('#') > -1 && link.split('#')[0] === config.LN+'//'+config.HN+config.PN));
}

export function getSiteFlag(sites){
    let site = null;
    for (let prop in sites){
        if (config[prop]){
            site = prop;
        }
    }

    return site;
}