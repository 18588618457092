import {config} from "./config";
import {dataLayer} from "./datalayer";
import {pixeler} from "./load_assets";
import {enc, gti, isFunction, isObject, removeEPs} from "./helpers";
import * as ut from "./ut";
import {getUserIds} from "./user";

//https://campaignmanager.google.com/trafficking/#/accounts/9515/advertisers/4390944/floodlight/4390944/activities?status=1&hidden=false

function createFloodlightUrl(opts) {
    let advertiser_id = opts.advertiser_id || 4390944,
        url = 'https://'+advertiser_id+'.fls.doubleclick.net/activityi;src='+advertiser_id+';type=' + opts.type + ';cat=' + opts.category + ';qty=1';

    opts.fields = opts.fields || {};
    opts.fields = {...opts.fields};

    //https://campaignmanager.google.com/trafficking/#/accounts/9515/advertisers/4390944/floodlight/4390944
    opts.fields.u1 = opts.fields.u1 || getUserIds() || config.user_id || dataLayer[0].user_id;
    opts.fields.u4 = opts.fields.u4 || location.href;
    opts.fields.u6 = opts.fields.u6 || ut.getSess('web_source') || null;
    opts.fields.u7 = opts.fields.u7 || ut.getSess('web_sub_source') || null;
    opts.fields.u8 = opts.fields.u8 || ut.getSess('landing_pg') || dataLayer[0].landing_pg || null;
    opts.fields.u12 = opts.fields.u12 || ut.getSess('conversion_pg') || dataLayer[0].conversion_pg || null;
    opts.fields.u14 = ut.getSess('utm_campaign') || dataLayer[0].utm_campaign || null;

    opts.fields.u9 = ut.getSess('id') || null;
    opts.fields.u10 = ut.getPage('id') || null;
    opts.fields.u11 = ut.getSess('utm_id') || dataLayer[0].utm_id || dataLayer[0].conversion_pg_query || null;

    opts.u = opts.u || getUserIds() || config.user_id;
    opts.num = opts.num || gti();
    opts.ord = opts.ord || gti(); //this needs to be set and as a number

    for (let field in opts.fields) {
        if (typeof opts.fields[field] === 'string'){
            opts.fields[field] = removeEPs(opts.fields[field], {just_pii: true});
        }

        url += ';' + field + '=' + enc(opts.fields[field]);
    }

    return url;
}

function loadGTag(){

}

export function DCMActivity(opts, category, fields, format){
    if (config.DNT || config.GPC){
        return;
    }

    if (typeof opts === 'string'){
        opts = {
            type: opts,
            category: category,
            fields: fields || {},
            format: typeof format !== 'undefined' ? format : 'iframe'
        };
    }

    pixeler({
        url: createFloodlightUrl({
            advertiser_id: opts.advertiser_id || 4390944,
            type: opts.type,
            category: opts.category,
            fields: opts.fields || {}
        }),
        bypass_cachebuster: true
    }, !opts.format || opts.format === 'iframe');
}

export function linkedinConversion(conversion_id){
    if (config.DNT || config.GPC){
        return;
    }

    if (isFunction(window.lintrk)){
        window.lintrk('track', { conversion_id: conversion_id});
    }
}

export function facebookEvent(event, data){
    if (config.DNT || config.GPC){
        return;
    }

    if (isFunction(window.fbq)) {
        if (isObject(data)){
            window.fbq('track', event, data);
        }
        else {
            window.fbq('track', event);
        }
    }
}