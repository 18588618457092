import { config } from "./config";
import {NR} from "./new_relic";
import { gti } from "./helpers";
import * as Base64 from "./base64";
import * as cookie_consent from "./cookie_consent";
import {getSiteFlag} from "./functions";

let cookie_clear = null,
    cookies_are_blocked = false,
    cookie_consent_cookies = ['STYXKEY_gdpr_consent', 'cookie-agreed', 'cookieControl', 'edf_cc'],
    cookie_overrides = ['chums'];

let site_config = {
    isCVI: {
        ov: ['introModal']
    }
}

let site = getSiteFlag(site_config);

if (site && Array.isArray(site.ov)){
    cookie_overrides = cookie_overrides.concat(site.ov);
}

function LS_set() {
    let set;
    try {
        LS_S('T', '?');
        LS_R('T');
        set = 1;
    } catch (e) {}
    return set;
}

function SS_set() {
    let set;
    try {
        SS_S('T', '?');
        SS_R('T');
        set = 1;
    } catch (e) {}
    return set;
}

export function LS_check() {
    let set;
    try {
        if (localStorage) {
            set = 1;
        }
    } catch (e) {}
    return set;
}

export function SS_check() {
    let set;
    try {
        if (sessionStorage) {
            set = 1;
        }
    } catch (e) {}
    return set;
}

export function LS_G(i, decode) {
    let item = null;

    if (LS_enabled) {
        try {
            item = localStorage.getItem(i);

            if (item && typeof item === 'string' && item.indexOf('{"d":') === 0){
                item = JSON.parse(item);

                if (item.d && item.e){
                    if (gti() >= item.e){
                        item = null;
                        LS_R(i);
                    }
                    else {
                        item = item.d;

                        if (decode){
                            item = JSON.parse(item);
                        }
                    }
                }
                else {
                    item = JSON.stringify(item);
                }
            }
        } catch (e) {
            item = null;
        }
    }

    if (item && decode){
        try {
            item = Base64.decode(item);
            item = JSON.parse(item);
        } catch(e){
            item = null;
        }
    }

    return item;
}

export function LS_R(i) {
    if (LS_enabled) {
        try {
            localStorage.removeItem(i);
        } catch (e) {}
    }
}

export function LS_S(i, v, expire, encode) {
    if (!acceptsCookies()){
        return;
    }

    if (LS_enabled) {
        if (encode && v && typeof v === 'object'){
            v = JSON.stringify(v);
        }

        if (expire && typeof expire === 'number'){
            v = JSON.stringify({d: i, e: gti()+expire});
        }

        if (encode){
            v = Base64.encode(v);
        }

        try {
            localStorage.setItem(i, v);
        } catch (e) {}
    }
}

export function SS_G(i, decode) {
    let item = null;

    if (SS_enabled) {
        try {
            item = sessionStorage.getItem(i);

            if (decode){
                item = Base64.decode(item);
            }

            if (decode && item && typeof item === 'string' && item.indexOf('{"d":') === 0){
                item = JSON.parse(item);

                if (item.d && item.e){
                    if (gti() >= item.e){
                        item = null;
                        SS_R(i);
                    }
                    else {
                        item = item.d;

                        if (decode){
                            item = JSON.parse(item);
                        }
                    }
                }
            }
            //don't enable until prior usages have been updated
            // else if (item && typeof item === 'string' && item.indexOf('{"') === 0){
            //     item = JSON.parse(item);
            // }
        } catch (e) {
            NR.error(e);
            item = null;
        }
    }

    return item;
}

export function SS_R(i) {
    if (SS_enabled) {
        try {
            sessionStorage.removeItem(i);
        } catch (e) {}
    }
}

export function SS_S(i, v, expire, encode) {
    if (!acceptsCookies() && i.indexOf('classy_trans') !== 0){ //calling it necessary
        return;
    }

    if (SS_enabled) {
        if (encode && typeof expire !== 'number'){
            expire = 1000*3600*24*7;
        }

        try {
            if (encode && v && typeof v === 'object'){
                v = JSON.stringify(v);
            }

            if (expire && typeof expire === 'number'){
                v = JSON.stringify({d: v, e: gti()+expire});
            }

            if (encode){
                v = Base64.encode(v);
            }

            sessionStorage.setItem(i, v);
        } catch (e) {}
    }
}

// function cookieGet(e){
//     return e;
// }

// function cookieSet(e, legacy){
//     if (e.indexOf('Max-Age=-999') > -1 || acceptsCookies()){
//         return e;
//     }
//
//     if (legacy){
//         return true;
//     }
// }

//https://stackoverflow.com/questions/49856794/how-to-disable-cookies-in-a-generic-way-till-cookies-are-accepted-by-user
//https://stackoverflow.com/questions/14344319/can-i-be-notified-of-cookie-changes-in-client-side-javascript
export function overrideCookies(){
    // if (!document.__defineGetter__) {
    //     Object.defineProperty(document, 'cookie', {
    //         get: cookieGet,
    //         set: function(e){
    //             cookieSet(e, true);
    //         },
    //     });
    // }
    // else {
    //     document.__defineGetter__("cookie", cookieGet);
    //     document.__defineSetter__("cookie", function(e) {
    //         cookieSet(e);
    //     });
    // }

    let lastCookie = document.cookie;
    // rename document.cookie to document._cookie, and redefine document.cookie
    let alternate = '_cookie';
    let nativeCookieDesc = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');
    Object.defineProperty(Document.prototype, alternate, nativeCookieDesc);
    Object.defineProperty(Document.prototype, 'cookie', {
        enumerable: true,
        configurable: true,
        get() {
            return this[alternate];
        },
        set(value) {
            let new_value = value.split(';')[0].split('=')[1];
            if (value.indexOf('Max-Age=-') > -1 || !new_value || acceptsCookies()){
                this[alternate] = value;
                let cookie = this[alternate];

                if (cookie !== lastCookie) {
                    try {
                        this.dispatchEvent(new CustomEvent('cookiechange', {
                            detail: {
                                oldValue: lastCookie,
                                newValue: cookie
                            }
                        }));
                    } finally {
                        lastCookie = cookie;
                    }
                }
            }
        }
    });
}

export function listenCookieChange(callback, interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(()=> {
        let cookie = document.cookie;
        if (cookie !== lastCookie) {
            try {
                callback({oldValue: lastCookie, newValue: cookie});
            } finally {
                lastCookie = cookie;
            }
        }
    }, interval);
}

export function acceptsCookies(){
    if (cookies_are_blocked){
        return false;
    }

    if (config.useCookieConsent){
        return !cookie_consent.cookies_were_rejected;
    }

    if (config.isGCA){
        return readCookie('cookieControl') === 'true';
    }

    if (config.isSSFH || config.isEU) {
        return ['1','2'].indexOf(readCookie('cookie-agreed')) > -1;
    }

    if (config.isCMEU) {
        return readCookie('cookie-agreed') === '1';
    }

    if (config.isNZA){
        return readCookie('STYXKEY_gdpr_consent') === 'true';
    }

    return true;
}

//good resource https://web.dev/first-party-cookie-recipes/
export function createCookie(a, v, days, strict_domain) {
    if (days !== -1){//note eraseCookie actually uses createCookie with day=-1
        if (!acceptsCookies() && cookie_consent_cookies.indexOf(a) === -1){
            return;
        }
    }

    days = typeof days !== 'undefined' ? days : 400;

    if (days > 400){  //why 400?
        days = 400;
    }

    let expires = '',
        s = config.isLocal ? [config.HN] : config.HN.split('.'),
        b = s.pop(),
        same_site = config.LN==='https:'?'SameSite=None; Secure':'SameSite=Strict;';

    if (days) {
        let expires_on = new Date(),
            expires_in = days*24 * 60 * 60;

        expires_on.setTime(expires_on.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + expires_on.toGMTString()+'; Max-Age='+expires_in;
    }

    if (config.isDevHosting){
        strict_domain = true;
    }

    document.cookie = a + '=' + v + expires + '; path=/' +(!!strict_domain ? '' : ';domain='+(config.isLocal ? config.HN : s.pop()+'.'+b))+'; '+same_site;

    if (days === -1){
        if (typeof getCookies()[a] !== 'undefined'){
            document.cookie = a + '=' + v + expires + '; path=/' +(!!strict_domain ? '' : ';domain='+config.HN)+'; '+same_site;
        }

        if (typeof getCookies()[a] !== 'undefined'){
            document.cookie = a + '=' + v + expires + '; path=/' +(!!strict_domain ? '' : ';domain=.'+config.HN)+'; '+same_site;
        }

        if (typeof getCookies()[a] !== 'undefined'){
            document.cookie = a + '=' + v + expires + '; path=/;domain=.'+config.HN+'; '+same_site;
        }
    }
}

export function getCookies() {
    let cookies = {},
        str = (document.cookie || '').split(';');

    if (str.length === 1 && !str[0]){
        return cookies;
    }

    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].trim();
        str[i] = str[i].split('=');
        cookies[str[i][0]] = str[i][1];
    }

    return cookies;
}

export function readCookie(name) {
    let nameEQ = name + '=',
        ca = (document.cookie || '').split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }

    return null;
}

export function isCookie(cookie) {
    return readCookie(cookie) || '';
}

export function eraseCookie(a) {
    createCookie(a, '', -1);
    document.cookie = a+'=; Max-Age=-99999999;';
}

export function clearCookies(exclude){
    exclude = exclude || [];

    if (!Array.isArray(exclude)){
        exclude = [];
    }

    for (let cookie in getCookies()){
        if (exclude.indexOf(cookie) === -1){
            eraseCookie(cookie);
        }
    }
}

export function allowCookies(){
    clearInterval(cookie_clear);
    cookies_are_blocked = false;
}

export function blockCookies(exclude){
    cookies_are_blocked = true;

    exclude = exclude || [];

    if (!Array.isArray(exclude)){
        exclude = [exclude];
    }

    clearStorage(exclude);

    cookie_clear = setInterval(function(){
        clearStorage(exclude);
    }, 500);

    overrideCookies();
}

export function clearStorage(exclude){
    clearCookies(exclude);

    //TODO ideally see if exclude items in storage, if so, save in memory, clear and then re-set
    try {
        localStorage.clear();
    } catch(e){}

    try {
        sessionStorage.clear();
    } catch(e){}
}

export function clearCookiesAndReload() {
    clearCookies();
    window.location.reload();
}

export function are_cookies_enabled() {
    let test = 'test',
        cookieEnabled = 'cookieEnabled',
        exists = false;

    if (window.navigator && cookieEnabled in navigator && !navigator[cookieEnabled]){
        return exists;
    }

    try {
        createCookie(test, test, 0);
        if (readCookie(test) === test) {
            exists = true;
        }
        eraseCookie(test);
    } catch (e) {
        exists = false;
    }

    return exists;
}

export function wuphf(key, value, exp){
    if (typeof value !== 'undefined'){
        if (typeof exp !== 'number'){
            exp = 30;
        }

        if (exp < 0){
            eraseCookie(key);
            SS_R(key);
            LS_R(key);
        }
        else {
            createCookie(key, value, exp);
            SS_S(key, value);
            LS_S(key, JSON.stringify({
                expire: new Date().getTime()+(1000*3600*24*exp),
                value: value
            }));
        }
    }
    else {
        let find = SS_G(key) || readCookie(key) || null;
        if (!find) {
            find = LS_G(key) || null;

            if (find) {
                let valid = false;
                try {
                    find = JSON.parse(find.replace(/""/g, '"'));
                    valid = true;
                } catch (e) {}

                if (valid) {
                    if (typeof find === 'object' && typeof find.value !== 'undefined' && typeof find.expire === 'number' && new Date().getTime() <= find.expire && find.value !== '[object Object]') {
                        find = find.value;
                    }
                    else {
                        LS_R(key);
                    }
                }
                else {
                    LS_R(key);
                    find = null;
                }
            }
        }

        return find;
    }
}

let LS_enabled = LS_check(),
    SS_enabled = SS_check();

if (LS_enabled) {
    if (!LS_set() && window.localStorage) {
        localStorage.clear();
    }
    if (!LS_set()) {
        LS_enabled = false;
    }
}

if (SS_enabled) {
    if (!SS_set() && window.sessionStorage) {
        sessionStorage.clear();
    }
    if (!SS_set()) {
        SS_enabled = false;
    }
}

export {
    LS_enabled,
    SS_enabled,
    cookie_consent_cookies,
    cookie_overrides
}