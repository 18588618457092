import {config} from './config';
import {appendHTML, forAll, getParentNode, hasClass, inview} from "./dom";
import {readCookie} from "./storage";
import {enc} from "./helpers";
import {describeStarterFormGAItem, GAEvent} from "./ga";

export function formDataToJSON(form) {
    let obj = {};

    if (!form || typeof form !== 'object'){
        return obj;
    }

    if (form.jquery){
        if (!form.length){
            return {};
        }

        form = form[0]; //extract form out of jquery object
    }

    forAll(form, 'input, select, textarea, button', function(element){
        let fieldset = getParentNode(element, 'fieldset'),
            fieldset_name = fieldset ? (fieldset.name || fieldset.getAttribute('aria-label')) : null,
            name = element.name || fieldset_name || element.getAttribute('aria-label'),
            value = element.value,
            aria = element.getAttribute('aria-checked') === 'true' || element.getAttribute('aria-current') === 'true';

        if (name) {
            name = name.replace(/ /, '_');

            if (fieldset_name){
                fieldset_name = fieldset_name.replace(/ /, '_');
            }

            if (element.tagName === 'SELECT' && element.multiple){
                value = [];

                forAll(element, 'option:checked', function(option){
                    if (option.value){
                        value.push(option.value);
                    }
                });

                obj[name] = value.join(',');
            }
            else if (element.tagName === 'INPUT' && ['radio', 'checkbox'].indexOf(element.type) > -1){
                if (element.checked || aria){
                    obj[name] = value;
                }
            }
            else if (element.tagName === 'BUTTON' && aria){
                let btn_name = name || element.dataset.id;

                if (fieldset){
                    btn_name = fieldset_name || fieldset.dataset.id || fieldset.id || btn_name;
                }

                obj[btn_name] = value || element.dataset.id || element.innerText;
            }
            else if (typeof obj[name] === 'undefined' || value) {
                obj[name] = value;
            }
        }
    });

    return obj;
}

export function encodeFormData(form){
    let querystring = '';

    if (window.FormData){
        querystring = [...new FormData(form).entries()]
                .map(x => `${enc(x[0])}=${enc(x[1])}`)
                .join('&');
    }

    return querystring;
}

export function trimEncodedStringto254(str){
    if (typeof str !== 'string'){
        return str;
    }

    if (str.length > 254){
        str = str.slice(0, 254);
    }

    try {
        let encoded_str = enc(str);
        if (encoded_str.length > 254){
            str = str.slice(0, str.length-(encoded_str.length-str.length));
        }
    } catch(e){
        if (str.length > 225){
            str = str.slice(0, 225);
        }
    }

    return str;
}

export function setFields(form) {
    if (typeof form === 'object' && 'get' in form) {
        form = form[0]; //de-jqueries the object
    }

    if (!form
        || hasClass(form, 'noTracking')
        || hasClass(form, 'custom_tracked')
        || form.getAttribute('role') === 'search'
    ) {
        return;
    }

    let source = 'source',
        sub_source = 'sub_source',
        cookie_source = readCookie(source),
        cookie_sub_source = readCookie(sub_source),
        i,
        temp,
        el,
        val;

    let input;
    if (cookie_source) {
        if (!form.querySelector('.' + source)) {
            appendHTML({
                a: form,
                elem: 'input',
                type: 'hidden',
                class: source,
                name: source
            });
        }

        form.querySelector('.' + source).value = cookie_source;
    }

    if (cookie_sub_source) {
        if (!form.querySelector('.' + sub_source)) {
            appendHTML({
                a: form,
                elem: 'input',
                type: 'hidden',
                class: sub_source,
                name: sub_source
            });
        }

        form.querySelector('.' + sub_source).value = cookie_sub_source;
    }

    for (i = 0; i < config.custom.length; i++) {
        temp = readCookie(config.custom[i]);
        if (temp) {
            input = 'custom_string' + config.custom_strings[i];
            if (!form.querySelector('.' + input)) {
                appendHTML({
                    a: form,
                    elem: 'input',
                    type: 'hidden',
                    class: input,
                    name: input
                });
            }

            el = form.querySelector('.' + input);
            val = el.value;

            if (val && val.indexOf(temp) > -1){
                //don't duplicate
            }
            else {
                el.value = temp + (config.custom[i] === 'addl_info' && val ? '_' + val : '');
            }
        }
    }
}

export function trackPlacement(form, placement, id){
    if (form && typeof form === 'object' && 'get' in form) {
        form = form[0]; //de-jqueries the object
    }

    if (!form){
        return;
    }

    if ((hasClass(form, 'starter-form') || hasClass(form, 'donation-form')) && !hasClass(form, 'edf-inview')){
        form.classList.add('edf-inview');

        inview(form, function(){
            GAEvent('view_promotion', {
                items: describeStarterFormGAItem(form, placement, id)
            });
        });
    }
}

export function trackForm(placement) {
    forAll('form', function(e){
        setFields(e);
        trackPlacement(e, placement);
    });
}